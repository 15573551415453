import React from 'react';
import './Card.scss';

interface CardProps {
    label: string;
    children: React.ReactNode;
}

function Card({label, children}: CardProps) {
    return <div className="card">
        <div className="card-label">
            {label}
        </div>
        <div className="card-content">
            {children}
        </div>
    </div>
}

export default Card;
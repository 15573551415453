import type { Section } from './section';
import Card from '../components/Card';


export default function TopTenSection({roulettes}: Section) {
    const instanceCounter: Record<string, number> = roulettes.map(roulette => roulette.val()).reduce((acc, roulette) => {
        const instance = roulette.instance;
        if (acc[instance]) {
            acc[instance] = acc[instance] + 1;
        } else {
            acc[instance] = 1;
        }
        return acc;
    }, []);
    const sorted = Object.entries(instanceCounter).sort((a, b) => b[1] - a[1]);
    const topN = sorted.slice(0, 10);
    console.log(topN)
    return <Card label="Top 10 Instances">
        <ol>
            {topN.map(topInstances => 
                <li>{topInstances[0]}: {topInstances[1]} runs</li>
            )}
        </ol>
    </Card>

}
import React from "react";
import './Loading.scss';
import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();

interface LoadingProps {
    error: Error | undefined;
    loading: boolean;
    children: React.ReactNode
}

function Loading({loading, error, children}: LoadingProps) {
    if(loading) {
        return <div className="lds-container"><div className="lds-ripple"><div></div><div></div></div></div>;
    } else if(error) {
        console.error(error);
        logEvent(analytics, 'exception', { fatal: false, description: error.message });
        return <div className="error">{error.message}</div>;
    } else {
        logEvent(analytics, 'page_view', {page_path: '/'})
        return <React.Fragment>{children}</React.Fragment>
    }
}

export default Loading;
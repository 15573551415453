import './Legend.scss';

interface LegendProps {
    data: {x: string, y: any}[],
    colorScale: string[]
};

function Legend({data, colorScale}: LegendProps) {
    return <div className="custom-legend">
        {data.map(({x}, idx) => {
            return (<div key={x} className='legend-item'>
                <span className="pip" style={{backgroundColor: colorScale[idx]}} />
                <span>{x}</span>
            </div>);
        })}
    </div>
}

export default Legend;
import './TimeStats.scss';
import type { DataSnapshot } from "firebase/database";
import { differenceInSeconds } from "date-fns";

interface TimeStatsProps {
    roulettes: DataSnapshot[]
}

function median(nums: number[]) {
    nums.sort();
    if (nums.length % 2 !== 0) {
        return nums[Math.floor(nums.length / 2)];
    }
  
    return (nums[Math.floor((nums.length-1)/2)] + 
            nums[Math.floor(nums.length / 2)]) / 2;
}

export default function TimeStats({roulettes}: TimeStatsProps) {
    const withSecondDuration = roulettes.map( x => x.val()).map((roulette) => {
        const startDate = new Date(roulette.startTime);
        const endDate = new Date(roulette.endTime);

        if (startDate && endDate) {
           const duration = Math.abs(differenceInSeconds(endDate, startDate, {roundingMethod: 'round'}));
           return {
                ...roulette,
                ...{ duration }
           };
        } else {
            return undefined; //incomplete data  so we are dropping it.
        } 
    }).filter(x => !!x);

    const durationAvg = withSecondDuration.reduce((acc, roulette) => acc + roulette.duration, 0) / withSecondDuration.length;
    const durationMedian = median(withSecondDuration.map(roulette => roulette.duration));
    const shortestDuration = withSecondDuration.reduce((acc, roulette) => roulette.duration < acc.duration ? roulette: acc, withSecondDuration[0]);
    const longesetDuration = withSecondDuration.reduce((acc, roulette) => roulette.duration > acc.duration ? roulette: acc, withSecondDuration[0]);
    const inProgressPercentage = withSecondDuration.filter(roulette => roulette.inProgress).length / withSecondDuration.length;

    return (
        <div className="timestats">
            <h3>Interesting duration stats</h3>
            <dl>
                <dt>Average Duration (mean)</dt>
                <dd>{Math.floor(durationAvg / 60)} minutes {Math.floor(durationAvg % 60)} seconds</dd>

                <dt>Average Duration (median)</dt>
                <dd>{Math.floor(durationMedian / 60)} minutes {Math.floor(durationMedian % 60)} seconds</dd>

                <dt>Shortest Instance</dt>
                <dd>{shortestDuration.instance} ({Math.floor(shortestDuration.duration / 60)}:{Math.floor(shortestDuration.duration % 60)})</dd>

                <dt>Longest Instance</dt>
                <dd>{longesetDuration.instance} ({Math.floor(longesetDuration.duration / 60)}:{Math.floor(longesetDuration.duration % 60)})</dd>

                <dt>Joined In Progress</dt>
                <dd>{Math.round(inProgressPercentage * 100)}%</dd>
            </dl>
        </div>)

}
import Card from '../components/Card';
import Pie from '../components/Pie';
import Legend from '../components/Legend';
import type { Section } from './section';

const jobColors = {
    PLD: 'rgb(21, 28, 100)',
    WAR: 'rgb(153, 23, 23)',
    DRK: 'rgb(136, 14, 79)',
    GNB: 'rgb(78, 52, 46)',

    WHM: 'rgb(117, 117, 117)',
    SCH: 'rgb(121, 134, 203)',
    AST: 'rgb(121, 85, 72)',
    SGE: 'rgb(79, 195, 247)',

    NIN: 'rgb(211, 47, 47)',
    SAM: 'rgb(255, 202, 40)',
    MNK: 'rgb(255, 152, 0)',
    DRG: 'rgb(63, 81, 181)',
    RPR: 'rgb(254, 179, 0)',
    VPR: 'rgb(216, 67, 21)',

    BRD: 'rgb(158, 157, 36)',
    MCH: 'rgb(0, 151, 167)',
    DNC: 'rgb(244, 143, 177)',

    BLM: 'rgb(126, 87, 194)',
    RDM: 'rgb(233, 30, 99)',
    SMN: 'rgb(121, 134, 203)',
    PCT: 'rgb(253, 216, 53)'
};

const jobNames = {
    PLD: 'Paladin',
    WAR: 'Warrior',
    DRK: 'Dark Knight',
    GNB: 'Gunbreaker',

    WHM: 'White Mage',
    SCH: 'Scholar',
    AST: 'Astrologian',
    SGE: 'Sage',

    NIN: 'Ninja',
    SAM: 'Samurai',
    MNK: 'Monk',
    DRG: 'Dragoon',
    RPR: 'Reaper',
    VPR: 'Viper',

    BRD: 'Bard',
    MCH: 'Machinist',
    DNC: 'Dancer',

    BLM: 'Black Mage',
    RDM: 'Red Mage',
    SMN: 'Summoner',
    PCT: 'Pictomancer'
};

type jobs = keyof typeof jobColors;

function JobSection({roulettes}: Section) {
    let chartData = roulettes.map(x => x.val()).reduce((acc, { job }) => {
        if (acc[job]) {
            acc[job] = acc[job] + 1;
        } else {
            acc[job] = 1;
        }
        return acc;
    }, {});
    chartData = Object.entries(chartData).map(([type, count]) => ({x: type, y: count}));
    const nameData = chartData.map(({x}: {x: jobs}) => ({x: jobNames[x] ?? ''}));
    const colorScale = chartData.map(({ x }: {x: jobs, y: number}) => jobColors[x]);
    return <Card label="Roulettes By Job*">
        <Pie data={chartData} colorScale={colorScale} dataSize={roulettes.length} />
        <div>
            <Legend data={nameData} colorScale={colorScale} />
        </div>
    </Card>
}

export default JobSection;
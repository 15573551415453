import { VictoryPie, VictoryTooltip, VictoryContainer } from 'victory';
import type { ColorScalePropType } from 'victory';


interface PieParams {
    data: any[];
    colorScale: ColorScalePropType;
    dataSize?: number;
}

function Pie({data, colorScale, dataSize}: PieParams) {
    return <div style={{width: "100%", height: '400px'}}>
        <VictoryPie
            containerComponent={<VictoryContainer style={{touchAction: "auto"}} />}
            padding={10}
            labels={({ datum }) => `${datum.x}: ${dataSize ? Math.floor((datum.y / dataSize * 100)) + "%" : datum.y}`}
            labelComponent={<VictoryTooltip flyoutPadding={({top: 0, bottom: 0, right: 7, left: 7})}/>}
            colorScale={colorScale}
            data={data}
            animate={{
                duration: 2000
            }}
        />
    </div>
}

export default Pie;
import './ProgressBar.scss';

interface ProgressBarParams {
    percentage: number;
    width: number
}

function ProgressBar({percentage, width}: ProgressBarParams) {
    const progress = percentage * width;
    return <div className="progress-bar-track" style={{width: `${width}px`}}>
        <div className="progress-bar" style={{width: `${progress}px`}} />
    </div>
}

export default ProgressBar;
import { VictoryAxis, VictoryChart, VictoryBar, VictoryTooltip, VictoryContainer } from 'victory';
import Card from '../components/Card';
import type { Section } from './section';
import { differenceInMinutes } from 'date-fns';

import TimeStats from '../components/TimeStats';

function TimeSection({roulettes}: Section) {
    const nearestMinute = roulettes.map(x => x.val()).map((roulette) => {
        const startDate = new Date(roulette.startTime);
        const endDate = new Date(roulette.endTime);

        if (startDate && endDate) {
           const durationMinutes = Math.abs(differenceInMinutes(endDate, startDate, {roundingMethod: 'round'}));
           return {
                ...roulette,
                ...{ durationMinutes }
           };
        } else {
            return undefined; //incomplete data  so we are dropping it.
        } 
    }).filter(x => !!x);

    const values = nearestMinute.reduce((acc, roulette) => {
        //y is count, x is minutes
        if(acc[roulette.durationMinutes]) {
            acc[roulette.durationMinutes] = acc[roulette.durationMinutes] + 1;
        } else {
            acc[roulette.durationMinutes] = 1;
        }

        return acc;
    }, {})
    
    const chartData = Object.entries(values).map((minuteBreakdown): {x:number, y:number} => {
        return {x: parseInt(minuteBreakdown[0]), y: minuteBreakdown[1] as number};
    }, []);
    const color = '#926f17';
    return <Card label="Duration Breakdown (rounded to nearest minute)">
        <VictoryChart 
            domainPadding={{x: 5}}
            containerComponent={<VictoryContainer style={{touchAction: "auto"}} />}
            padding={{top: 3, bottom: 43, left: 40, right: 0}}
            >
            <VictoryBar
                labelComponent={<VictoryTooltip flyoutPadding={({top: 0, bottom: 0, right: 7, left: 7})} style={{fontSize: 10}} />}
                data={chartData}
                labels={({ datum }) => `${datum.y} runs took ${datum.x} minutes`}
                style={{
                    data: {
                        fill: 'rgb(121, 134, 203)',
                    }
                }}
                animate={{
                    duration: 2000
                }}
            />
            <VictoryAxis label="Duration (nearest minute)" style={{
                ticks: {stroke: "grey", size: 5},
                axisLabel: {fontSize: 12, padding: 30, fill: color},
                tickLabels: {fontSize: 11, padding: 5, fill: color}
                }}
                tickCount={20}
                animate={{
                    duration: 2000
                }}/>
            <VictoryAxis dependentAxis label="# of runs" style={{
                ticks: {stroke: "grey", size: 5},
                axisLabel: {fontSize: 12, padding: 30, fill: color},
                tickLabels: {fontSize: 11, padding: 5, fill: color}
                }}
                animate={{
                    duration: 2000
                }}/>
        </VictoryChart>
        <TimeStats roulettes={roulettes} />
</Card>
}

export default TimeSection;
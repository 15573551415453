import './TwitchOverlay.scss';
import config from './config';
import { ref, getDatabase } from 'firebase/database';
import ProgressBar from './components/ProgressBar';
import { useList } from 'react-firebase-hooks/database';
import firebaseApp from './firebaseConnect';

const database = getDatabase(firebaseApp);

function TwitchOverlay() {
    const [snapshots, loading, error] = useList(ref(database, 'mentorRoulette'));
    let finishedRoulettes = 0;

    if(!error && !loading) {
        finishedRoulettes = config.unlistedRoulettes + (snapshots?.length ?? 0);
    }

    return <div className="chromabg">
        <div className="overlay-text">{ error ? error.message : `${finishedRoulettes.toLocaleString()}/${(2000).toLocaleString()}`}</div>
        <div>
            <ProgressBar width={300} percentage={finishedRoulettes / 2000} />
        </div>
    </div>
}

export default TwitchOverlay;
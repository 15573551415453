import {useRef, useEffect, useState} from 'react';
import Card from '../components/Card';
import type { Section } from './section';
import ProgressBar from '../components/ProgressBar';
import config from '../config';
import './ProgressSection.scss';


function ProgressSection({roulettes}: Section) {

    const [ contentWidth, setWidth ] = useState(0);

    const cardRef = useRef(null);
    const observer = useRef(
        new ResizeObserver(entries => {
          const { width } = entries[0].contentRect
            setWidth(width)
        })
      );

    useEffect(() => {
        const cardEl = cardRef.current;
        const obsRef = observer.current;
        if (cardEl) {
          obsRef.observe(cardEl)
        }
    
        return () => {
            if(cardEl) {
                obsRef.unobserve(cardEl);
            }
        }
      }, [cardRef, observer]);
    
    let finishedRoulettes = config.unlistedRoulettes + roulettes.length;
    return <div ref={cardRef}>
        <Card label="Mentor Roulette Progress">
            <div className="progress-label">{finishedRoulettes.toLocaleString()}/{(2000).toLocaleString()}</div> 
            <ProgressBar percentage={finishedRoulettes / 2000} width={contentWidth - 40} />
        </Card>
    </div>
}

export default ProgressSection;
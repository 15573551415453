import Card from '../components/Card';
import Legend from '../components/Legend';
import Pie from '../components/Pie';
import type { Section } from './section';
import { expansionTypes } from '../dungeons';

const expansionColors = {
    'A Realm Reborn': '#387bc0',
    'Heavensward': '#030202',
    'Stormblood': '#a01118',
    'Shadowbringers': '#6480bf',
    'Endwalker': '#585d6e',
    'Dawntrail': '#f9e83f',
};

type expansions = keyof typeof expansionColors;

function ExpansionSection({roulettes}: Section) {
    let chartData = roulettes.map(x => x.val()).reduce((acc, instance) => {
        const type = Object.entries(expansionTypes).find(([_, categories]) => categories.includes(instance.instanceCategory))?.[0] ?? 'Unknown';
        if (!acc[type]) {
            acc[type] = 1;
        } else {
            acc[type] = acc[type] + 1;
        }
        return acc;
    }, {});
    chartData = Object.entries(chartData).map(([type, count]) => ({x: type, y: count}));
    const colorScale = chartData.map(({ x }: {x: expansions, y: number}) => expansionColors[x]);
    return <Card label="Roulettes By Expansion*">
        <Pie data={chartData} colorScale={colorScale} dataSize={roulettes.length} />
        <div>
            <Legend data={chartData} colorScale={colorScale} />
        </div>
    </Card>
}

export default ExpansionSection;
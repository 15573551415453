import './App.scss';
import { useState } from 'react';
import logo from './images/reznixmynach.svg';
import discordLogo from './images/Discord-Logo-Color.svg';
import fflogsLogo from './images/fflogs.webp';
import { ref, getDatabase } from 'firebase/database';
import { useList } from 'react-firebase-hooks/database';
import firebaseApp from './firebaseConnect';
import Loading from './components/Loading';
import config from './config';

import ProgressSection from './sections/ProgressSection';
import InstanceTypeSection from './sections/InstanceTypeSection';
import ExpansionSection from './sections/ExpansionSection';
import JobSection from './sections/JobSection';
import TimeSection from './sections/TimeSection';
import TopTenSection from './sections/TopTenSection';

const database = getDatabase(firebaseApp);

function App() {
  const [snapshots, loading, error] = useList(ref(database, 'mentorRoulette'));
  const [discordExpand, setDiscordExpand] = useState(false);
  return (
    <div className="App">
      <div className="content">
        <div className="container">
          <header>
            <h1>Mentor Roulette Stats</h1>
            <div className="header-icons">
              <div className="discord">
                <img src={discordLogo} alt="Discord cythrawll#1672" title="cythrawll#1672" onClick={() => setDiscordExpand(!discordExpand)}/>
                <span className={discordExpand ? 'expand' : ''}>cythrawll#1672</span>
              </div>
              <a href="https://www.fflogs.com/character/id/14413532" target="_blank" rel="noopener noreferrer">
                <img src={fflogsLogo} alt="FFLogs profile" />
              </a>
            </div>
          </header>
          <Loading error={error} loading={loading}>
            <ProgressSection roulettes={snapshots ?? []} />
            <InstanceTypeSection roulettes={snapshots ?? []} />
            <ExpansionSection roulettes={snapshots ?? []} />
            <JobSection roulettes={snapshots ?? []} />
            <TimeSection roulettes={snapshots ?? []} />
            <TopTenSection roulettes={snapshots ?? []} />
          </Loading>
          <p className="foot-note">* {config.unlistedRoulettes} roulettes are not included in data. Reznix did these before I started logging data. {snapshots?.length} rouletes recorded.</p>
        </div>
      </div>
      <div className="footer">
        <div className="footer-left">
          <div className="rez" />
          <div className="rez-md" />
          <div className="rez-sm" />
        </div>
        <div className="footer-right">
          <img src={logo} className="footer-logo" alt="Reznix Mynach" />
        </div>
      </div>
    </div>
  );
}

export default App;

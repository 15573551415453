import Card from '../components/Card';
import Pie from '../components/Pie';
import type { Section } from './section';
import Legend from '../components/Legend';
import { dungeonTypes } from '../dungeons';

const colorScale = ['#FFF1C9', '#F7B7A3', '#EA5F89', '#9B3192', '#57167E', '#2B0B3F','#EC6B56', '#FFC154', '#47B39C']

function InstanceTypeSection({roulettes}: Section) {
    let chartData = roulettes.map(x => x.val()).reduce((acc, instance) => {
        const type = Object.entries(dungeonTypes).find(([_, categories]) => categories.includes(instance.instanceCategory))?.[0] ?? 'Unknown';
        if (!acc[type]) {
            acc[type] = 1;
        } else {
            acc[type] = acc[type] + 1;
        }
        return acc;
    }, {});
    chartData = Object.entries(chartData).map(([type, count]) => ({x: type, y: count}));
    return <Card label="Roulettes By Instance Type*">
        <Pie data={chartData} colorScale={colorScale} dataSize={roulettes.length} />
        <div>
            <Legend data={chartData} colorScale={colorScale} />
        </div>
    </Card>
}

export default InstanceTypeSection;